import {RequesterCaseApproveRuleTypes} from 'appRedux/actions/requestCase/types';
import {
    WorkflowStatusRuleApproverItemTypes,
    WorkflowStatusRuleApproversGroupItemTypes,
    WorkflowStatusRuleItemTypes,
} from 'appRedux/actions/workflow/types';

import {CASE_STATUS_CHANGE_DEFAULT} from 'components/Forms/RequesterCase/helper';

import {WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED} from 'components/Forms/Workflow/workflowStatusRule/helper';

export const checkIsCurrentUserCanApproveStatusChanging = (
    rules: WorkflowStatusRuleItemTypes[],
    currentUserId: number,
): boolean => {
    const approvers: WorkflowStatusRuleApproverItemTypes[] = [];
    const groups: WorkflowStatusRuleApproversGroupItemTypes[] = [];
    rules.forEach(rule => {
        if (rule.type === WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED) {
            const currentRuleApprovers: WorkflowStatusRuleApproverItemTypes[] = rule.approvers;
            const currentRuleGroups: WorkflowStatusRuleApproversGroupItemTypes[] = rule.groups;
            currentRuleApprovers.forEach(currentRuleApprover => {
                if (!approvers.some(approver => approver.id === currentRuleApprover.id)) {
                    approvers.push(currentRuleApprover);
                }
            });
            currentRuleGroups.forEach(currentRuleGroup => {
                if (!groups.some(group => group.id === currentRuleGroup.id)) {
                    groups.push(currentRuleGroup);
                }
            });
        }
    });

    const isCurrentUserPresentedInApproversList = approvers.some(item => Number(item.id) === currentUserId);
    let isCurrentUserPresentedInGroupsApproversList = false;
    for (let i = 0, n = groups.length; i < n; i++) {
        const group = groups[i];
        if (group && group.agents && group.agents.some(agent => agent.id === currentUserId)) {
            isCurrentUserPresentedInGroupsApproversList = true;
        }
    }
    return isCurrentUserPresentedInApproversList || isCurrentUserPresentedInGroupsApproversList;
};

export const checkIsAtLeastOneApprovePresented = (approveRules: RequesterCaseApproveRuleTypes[]): boolean => {
    if (!approveRules || !Array.isArray(approveRules)) {
        return false;
    }
    for (let i = 0, n = approveRules.length; i < n; i++) {
        const {changeRequestStatus, approvers} = approveRules[i];
        const approvedSolution = approvers.find(approver => approver.status !== CASE_STATUS_CHANGE_DEFAULT);
        if (approvedSolution) {
            return true;
        }
    }
    return false;
};

export const checkIsAlreadyApprovedByCurrentUser = (
    approveRules: RequesterCaseApproveRuleTypes[],
    currentUserId: number,
): boolean => {
    if (!approveRules || !Array.isArray(approveRules)) {
        return false;
    }
    for (let i = 0, n = approveRules.length; i < n; i++) {
        const {changeRequestStatus, approvers} = approveRules[i];
        const approvedSolution = approvers.find(approver => approver.approverId === currentUserId);
        if (approvedSolution && changeRequestStatus === CASE_STATUS_CHANGE_DEFAULT) {
            return true;
        }
    }
    return false;
};

export const getNotApprovedStatusChangesForCurrentUser = (
    approveRules: RequesterCaseApproveRuleTypes[],
    currentUserId: number,
): RequesterCaseApproveRuleTypes | null => {
    if (!approveRules || !Array.isArray(approveRules)) {
        return null;
    }
    for (let i = 0, n = approveRules.length; i < n; i++) {
        const item = approveRules[i];
        const {changeRequestStatus, approvers} = item;
        const approvedSolution = approvers.find(approver => approver.permittedApproverIds.includes(currentUserId));
        if (approvedSolution && changeRequestStatus === CASE_STATUS_CHANGE_DEFAULT) {
            return item;
        }
    }
    return null;
};

export const getNotApprovedStatusChanges = (
    approveRules: RequesterCaseApproveRuleTypes[],
): RequesterCaseApproveRuleTypes[] => {
    return approveRules.filter(item => item.changeRequestStatus === CASE_STATUS_CHANGE_DEFAULT);
};

export const getRequiredApprovesNumber = (approveRules: RequesterCaseApproveRuleTypes[]): number => {
    let result = 0;
    approveRules.forEach(item => {
        result += Number(item.requiredApproval);
    });
    return result;
};

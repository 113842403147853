import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    FORMS_LIST,
    FORMS_LIST_BY_ORGANIZATION,
    FORMS_COPY,
    FORM_MULTIPLE_STATUS_CHANGING,
} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';

import FormsDataGrid from 'pages/admin/formsList/FormsDataGrid';
import FormsSuperAdminDataGrid from 'pages/admin/formsList/FormsSuperAdminDataGrid';
import {isMaximalActiveFormsNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import ActiveFormsNumberQuotaAlertMultiplePopup from 'pages/admin/formsList/ActiveFormsNumberQuotaAlertMultiplePopup';

import {isSuperAdminUser} from 'helpers/roles';

const FormsList: FC = () => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {selectedIds, setSelectedIds, myOrganization} = useContext(AdminContext);
    const {setTopBarTitle, setTopBarSubtitle, setBackLinkLabel, setBackLink} = useContext(RouteContext);
    const {isFormCreateEnabled, isFormEditEnabled, toggleIsTokenUpdated} = useContext(PermissionContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isQuotaHard, setIsQuotaHard] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formsList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const copyMultiple = useCallback(data => dispatch({type: FORMS_COPY.REQUEST, payload: data}), [dispatch]);

    const changeStatusMultiple = useCallback(
        data => dispatch({type: FORM_MULTIPLE_STATUS_CHANGING.REQUEST, payload: data}),
        [dispatch],
    );

    const isSuperAdminPage = profile ? isSuperAdminUser(profile) : false;

    const clearAllSelections = () => {
        setSelectedIds([]);
    };

    const onCopyFormClick = () => {
        copyMultiple({
            ids: selectedIds,
            showAlert,
            callback: () => {
                toggleIsTokenUpdated();
                clearAllSelections();
            },
        });
    };

    const onActivateAllClick = () => {
        const activeForms = formsList ? formsList.filter(item => item.isActive) : [];
        if (myOrganization && formsList) {
            if (isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + selectedIds.length, true)) {
                setIsQuotaHard(true);
                toggleIsOpened();
            } else if (
                isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + selectedIds.length, false)
            ) {
                setIsQuotaHard(false);
                toggleIsOpened();
            } else {
                changeStatusMultiple({
                    ids: selectedIds,
                    isActive: true,
                    callback: clearAllSelections,
                    showAlert,
                });
            }
        }
    };

    const onDeactivateAllClick = () => {
        changeStatusMultiple({
            ids: selectedIds,
            isActive: false,
            callback: clearAllSelections,
            showAlert,
        });
    };

    useEffect(() => {
        if (isSuperAdminPage) {
            getFormsList({
                showAlert,
            });
        } else if (myOrganization) {
            getFormsListByOrganization({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [isSuperAdminPage, myOrganization]);

    useEffect(() => {
        setTopBarTitle('');
        setTopBarSubtitle('');
        setBackLink('');
        setBackLinkLabel('');
    }, []);

    if (isSuperAdminPage) {
        return (
            <>
                <DataGridWrapper itemsList={formsList} itemsTotalNumber={formsList ? formsList.length : 0}>
                    {formsList && <FormsSuperAdminDataGrid formsList={formsList} />}
                </DataGridWrapper>
            </>
        );
    }
    return (
        <>
            <DataGridWrapper
                itemsList={formsList}
                itemsTotalNumber={formsList ? formsList.length : 0}
                onCopyClicked={isFormCreateEnabled ? onCopyFormClick : undefined}
                onActivateClicked={isFormEditEnabled ? onActivateAllClick : undefined}
                onDeactivateClicked={isFormEditEnabled ? onDeactivateAllClick : undefined}
            >
                {formsList && <FormsDataGrid />}
            </DataGridWrapper>
            <ActiveFormsNumberQuotaAlertMultiplePopup
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                isQuotaHard={isQuotaHard}
                toggleIsOpened={toggleIsOpened}
                changeStatusMultiple={changeStatusMultiple}
            />
        </>
    );
};

export default FormsList;

import get from 'lodash/get';
import * as EmailValidator from 'email-validator';
import validator from 'validator';

import {FormPageTypes, FormPopupTypes, FormSectionTypes, FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterDocumentResponseType, RequesterSignatureResponseType} from 'appRedux/actions/profile/types';
import {RequesterCasePopupType, SubFormRequestTypes} from 'appRedux/actions/requestCase/types';

import {KeysType} from 'contexts/crypto/context';

import {
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_UPLOADER,
    FORM_FIELD_TEXTAREA,
    FORM_FIELD_SIGNATURE,
    TEXTAREA_DEFAULT_LENGTH,
    VALIDATION_TYPE_NONE,
    VALIDATION_TYPE_URL,
    VALIDATION_TYPE_EMAIL,
    VALIDATION_TYPE_PHONE,
    VALIDATION_TYPE_NUMBER,
    VALIDATION_TYPE_IBAN,
    VALIDATION_TYPE_BIC,
    VALIDATION_TYPE_DECIMAL,
    VALIDATION_TYPE_TIME,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {ContentItemType} from 'pages/client/form/partials/types';

import {htmlTagsPresented} from 'helpers/validation';
import {encryptStringWithKey, decryptStringWithKey, unwrapKey} from 'helpers/cryptoApiHelper';

import {STATUS_OPENING} from 'config/index';
import {
    ERROR_MESSAGE_WRONG_DOCUMENT_SIZE,
    ERROR_MESSAGE_WRONG_DOCUMENT_TYPE,
    ERROR_MESSAGE_EMAIL_NOT_VERIFIED,
} from 'config/errors';
import {
    DECIMAL_DOT_SEPARATOR_REGEX,
    DECIMAL_COMMA_SEPARATOR_REGEX,
    TIME_REGEX,
    BIC_REGEX,
    NUMBER_REGEX,
    PHONE_REGEX,
    IBAN_REGEX,
} from 'helpers/validation';

export const isValueIncorrect = (field: FormFieldTypes, value: string): boolean => {
    const {validationType, type} = field;

    if (field.type === FORM_FIELD_TEXTAREA && field.isRequired && field.minLength) {
        return field.minLength > value.length;
    } else if (field.type === FORM_FIELD_TEXTAREA && field.isRequired) {
        return value.length === 0;
    }

    if (type !== FORM_FIELD_TEXT_INPUT) {
        return false;
    }

    switch (validationType) {
        case VALIDATION_TYPE_EMAIL:
            return !EmailValidator.validate(value);
        case VALIDATION_TYPE_URL:
            return !validator.isURL(value);
        case VALIDATION_TYPE_PHONE:
            return !PHONE_REGEX.test(value);
        case VALIDATION_TYPE_NUMBER:
            return !NUMBER_REGEX.test(value);
        case VALIDATION_TYPE_IBAN:
            return !IBAN_REGEX.test(value);
        case VALIDATION_TYPE_BIC:
            return !BIC_REGEX.test(value);
        case VALIDATION_TYPE_DECIMAL:
            return !DECIMAL_DOT_SEPARATOR_REGEX.test(value) && !DECIMAL_COMMA_SEPARATOR_REGEX.test(value);
        case VALIDATION_TYPE_TIME:
            return !TIME_REGEX.test(value);
        default:
            return false;
    }
};

export const getInitialFieldValue = (
    pageId: number,
    sectionId: number,
    fieldId: number,
    clientInformation: unknown,
    agentInformation: unknown,
    isAgentPage: boolean,
    isPopup?: boolean,
    popupContent?: unknown,
) => {
    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const fieldKeyword = `field-${fieldId}`;

    if (isPopup) {
        return get(popupContent, fieldKeyword, '');
    }

    return isAgentPage
        ? get(agentInformation, [pageKeyword, sectionKeyword, fieldKeyword], '')
        : get(clientInformation, [pageKeyword, sectionKeyword, fieldKeyword], '');
};

export const isContinueUnavailable = (
    page: FormPageTypes,
    clientInformation: unknown,
    agentInformation: unknown,
    status: string | null,
    files: RequesterDocumentResponseType[],
    signatures: RequesterSignatureResponseType[],
    relatedSectionsIds: number[],
    selectedRelatedSectionsIds: number[],
    popupItems: RequesterCasePopupType[],
): boolean => {
    const {sections, isAgentPage} = page;

    for (let i = 0, n = sections.length; i < n; i++) {
        if (relatedSectionsIds.includes(sections[i].id) && !selectedRelatedSectionsIds.includes(sections[i].id))
            continue;

        const section = isSaveSectionUnavailable(
            page.id,
            sections[i],
            clientInformation,
            agentInformation,
            status,
            files,
            signatures,
            popupItems,
            isAgentPage,
        );

        if (section) return true;
    }
    return false;
};

export const getEmptyRequiredField = (
    page: FormPageTypes,
    clientInformation: unknown,
    agentInformation: unknown,
    files: RequesterDocumentResponseType[],
    signatures: RequesterSignatureResponseType[],
    isAgentPage: boolean,
) => {
    const {sections} = page;
    for (let i = 0, n = sections.length; i < n; i++) {
        const fieldId = getEmptySectionRequiredField(
            page.id,
            sections[i],
            clientInformation,
            agentInformation,
            files,
            signatures,
            isAgentPage,
        );
        if (fieldId) {
            return fieldId;
        }
    }
    return null;
};

export const isValidationRequired = (currentField: FormFieldTypes) => {
    return (
        (currentField.isRequired && currentField.type !== FORM_FIELD_UPLOADER) ||
        currentField.validationType !== VALIDATION_TYPE_NONE ||
        (currentField.isRequired && currentField.type === FORM_FIELD_TEXTAREA)
    );
};

export const isSaveUnavailable = (currentField: FormFieldTypes, value: string | null): boolean => {
    if (currentField.isRequired && (!value || value === '')) {
        return true;
    }
    if (value) {
        return isValueIncorrect(currentField, value);
    }
    return false;
};

export const getEmptySectionRequiredField = (
    pageId: number,
    currentSection: FormSectionTypes,
    clientInformation: unknown,
    agentInformation: unknown,
    files: RequesterDocumentResponseType[],
    signatures: RequesterSignatureResponseType[],
    isAgentPage: boolean,
) => {
    const {fields} = currentSection;
    for (let j = 0, m = fields.length; j < m; j++) {
        const currentField = fields[j];
        const value = getInitialFieldValue(
            pageId,
            currentSection.id,
            currentField.id,
            clientInformation,
            agentInformation,
            isAgentPage,
        );
        if (!htmlTagsPresented(value)) {
            return currentField.id;
        }
        if (isValidationRequired(currentField) || isRequiredFileAbsent(currentField, files)) {
            if (currentField.type === FORM_FIELD_SIGNATURE) {
                const isSignaturePresented = signatures.some(item => Number(item.field) === Number(currentField.id));
                if (!isSignaturePresented) {
                    return currentField.id;
                }
            } else if (isSaveUnavailable(currentField, value)) {
                return currentField.id;
            }
        }
    }
    return null;
};

export const getEmptyRequiredPopups = (page: FormPageTypes, popupItems: RequesterCasePopupType[]): number | null => {
    const {sections} = page;
    for (let i = 0, n = sections.length; i < n; i++) {
        const {popups} = sections[i];
        for (let j = 0, l = popups.length; j < l; j++) {
            const popup = popups[j];
            if (popup.isRequired) {
                const isPopupSubmitted = popupItems.some(
                    popupItem => popupItem.popupId === popup.id && popupItem.isSubmitted,
                );
                if (!isPopupSubmitted) {
                    return popup.id;
                }
            }
        }
    }
    return null;
};

export const isPopupSubmitDisabled = (
    fields: FormFieldTypes[],
    popupContent: ContentItemType | null,
    files: RequesterDocumentResponseType[],
    signatures: RequesterSignatureResponseType[],
    requesterCaseHasPopup?: number,
): boolean => {
    console.log('fields', fields);

    for (let i = 0, n = fields.length; i < n; i++) {
        const currentField = fields[i];
        const fieldKeyword = `field-${currentField.id}`;
        const value = get(popupContent, fieldKeyword, null);
        if (value && !htmlTagsPresented(value)) {
            return true;
        }
        if (isValidationRequired(currentField)) {
            if (currentField.type === FORM_FIELD_SIGNATURE) {
                if (!isSignatureInPopupPresented(currentField, signatures, requesterCaseHasPopup)) {
                    return true;
                }
            } else if (isSaveUnavailable(currentField, value)) {
                return true;
            }
        }
        if (currentField.isRequired && currentField.type === FORM_FIELD_UPLOADER) {
            if (!isFileInPopupPresented(currentField, files, requesterCaseHasPopup)) {
                return true;
            }
        }
    }
    return false;
};

export const getEmptyPopupRequiredField = (
    popup: FormPopupTypes,
    popupContent: ContentItemType | null,
    files: RequesterDocumentResponseType[],
    signatures: RequesterSignatureResponseType[],
    requesterCaseHasPopup?: number,
): number | null => {
    const fields = get(popup, 'fields', []);
    for (let i = 0, n = fields.length; i < n; i++) {
        const currentField = fields[i];
        const fieldKeyword = `field-${currentField.id}`;
        const value = get(popupContent, fieldKeyword, null);
        if (value && !htmlTagsPresented(value)) {
            return currentField.id;
        }
        if (isValidationRequired(currentField)) {
            if (currentField.type === FORM_FIELD_SIGNATURE) {
                if (!isSignatureInPopupPresented(currentField, signatures, requesterCaseHasPopup)) {
                    return currentField.id;
                }
            } else if (isSaveUnavailable(currentField, value)) {
                return currentField.id;
            }
        }
        if (currentField.isRequired && currentField.type === FORM_FIELD_UPLOADER) {
            if (!isFileInPopupPresented(currentField, files, requesterCaseHasPopup)) {
                return currentField.id;
            }
        }
    }
    return null;
};

export const isFileInPopupPresented = (
    currentField: FormFieldTypes,
    files: RequesterDocumentResponseType[],
    requesterCaseHasPopup?: number,
) => {
    return files.some(
        item =>
            Number(item.fieldId) === Number(currentField.id) &&
            Number(item.requesterCaseHasPopupId) === Number(requesterCaseHasPopup),
    );
};

export const isSignatureInPopupPresented = (
    currentField: FormFieldTypes,
    signatures: RequesterSignatureResponseType[],
    requesterCaseHasPopup?: number,
) => {
    return signatures.some(
        item =>
            Number(item.field) === Number(currentField.id) &&
            Number(item.requesterCaseHasPopup) === Number(requesterCaseHasPopup),
    );
};

export const isRequiredFileAbsent = (currentField: FormFieldTypes, files: RequesterDocumentResponseType[]): boolean => {
    if (currentField.isRequired && currentField.type === FORM_FIELD_UPLOADER) {
        return !files.some(file => file.formField?.includes(`field-${currentField.id}`));
    }
    return false;
};

export const isFieldsContainHtmlTag = (
    pageId: number,
    currentSection: FormSectionTypes,
    clientInformation: unknown,
    agentInformation: unknown,
    isAgentPage: boolean,
): boolean => {
    const {fields} = currentSection;

    for (let j = 0, m = fields.length; j < m; j++) {
        const currentField = fields[j];
        const value = getInitialFieldValue(
            pageId,
            currentSection.id,
            currentField.id,
            clientInformation,
            agentInformation,
            isAgentPage,
        );
        if (!htmlTagsPresented(value)) {
            return true;
        }
    }
    return false;
};

export const isSaveSectionUnavailable = (
    pageId: number,
    currentSection: FormSectionTypes,
    clientInformation: unknown,
    agentInformation: unknown,
    status: string | null,
    files: RequesterDocumentResponseType[],
    signatures: RequesterSignatureResponseType[],
    popupItems: RequesterCasePopupType[],
    isAgentPage: boolean,
): boolean => {
    const {isRequired, fields, popups} = currentSection;

    if (isFieldsContainHtmlTag(pageId, currentSection, clientInformation, agentInformation, isAgentPage)) {
        return true;
    }

    if (!isRequired && status === STATUS_OPENING) {
        return false;
    }

    for (let j = 0, m = fields.length; j < m; j++) {
        const currentField = fields[j];
        if (isValidationRequired(currentField) || isRequiredFileAbsent(currentField, files)) {
            const value = getInitialFieldValue(
                pageId,
                currentSection.id,
                currentField.id,
                clientInformation,
                agentInformation,
                isAgentPage,
            );
            if (currentField.type === FORM_FIELD_SIGNATURE) {
                if (!signatures.some(item => Number(item.field) === Number(currentField.id))) {
                    return true;
                }
            } else if (isSaveUnavailable(currentField, value)) {
                return true;
            }
        }
    }
    for (let y = 0, l = popups.length; y < l; y++) {
        const currentPopup = popups[y];
        if (currentPopup.isRequired && currentPopup.fields.length > 0) {
            const currentPopupItems = popupItems.filter(item => item.popupId === currentPopup.id && item.isSubmitted);
            if (currentPopupItems.length === 0) {
                return true;
            }
        }
    }
    return false;
};

export const getErrorMessage = (field: FormFieldTypes, t: (trans: string) => string, value: string | null): string => {
    let errorMsg = '';
    if (value && !htmlTagsPresented(value)) {
        return t('messages.validation.htmlTagsDenied');
    }
    if (value && field.type === FORM_FIELD_TEXTAREA && field.isRequired && value.length < Number(field.minLength)) {
        return t('messages.validation.errorTextareaMinXLength').replace('%X%', String(field.minLength));
    }
    switch (field.validationType) {
        case VALIDATION_TYPE_EMAIL:
            errorMsg = 'messages.validation.emailIncorrect';
            break;
        case VALIDATION_TYPE_URL:
            errorMsg = 'messages.validation.urlIncorrect';
            break;
        case VALIDATION_TYPE_PHONE:
            errorMsg = 'messages.validation.phoneIncorrect';
            break;
        case VALIDATION_TYPE_NUMBER:
            errorMsg = 'messages.validation.numberIncorrect';
            break;
        case VALIDATION_TYPE_IBAN:
            errorMsg = 'messages.validation.ibanIncorrect';
            break;
        case VALIDATION_TYPE_BIC:
            errorMsg = 'messages.validation.bicIncorrect';
            break;
        case VALIDATION_TYPE_DECIMAL:
            errorMsg = 'messages.validation.decimalFormatRequired';
            break;
        case VALIDATION_TYPE_TIME:
            errorMsg = 'messages.validation.timeFormalIncorrect';
            break;
        default:
            errorMsg = 'messages.error.errorIsRequired';
    }
    return t(errorMsg);
};

export const getFileUploadErrorMessage = (errors: string) => {
    switch (errors) {
        case ERROR_MESSAGE_WRONG_DOCUMENT_TYPE:
            return 'wrongDocumentTypeMessage';
        case ERROR_MESSAGE_WRONG_DOCUMENT_SIZE:
            return 'wrongDocumentSizeMessage';
        case ERROR_MESSAGE_EMAIL_NOT_VERIFIED:
            return 'CannotUploadFilesEmailNotVerified';
        default:
            return 'messages.error.somethingWentWrong';
    }
};

export const encryptClientInformation = async (clientInformation: unknown, caseKey: CryptoKey) => {
    let encryptedClientInformation = {
        enc: true,
    };
    for (const [pageKey, pageInformation] of Object.entries(clientInformation as object)) {
        if (pageKey !== 'enc') {
            for (const [sectionKey, sectionInformation] of Object.entries(pageInformation as object)) {
                for (const [sectionItemKey, sectionItemInformation] of Object.entries(sectionInformation as object)) {
                    const pageContent = get(encryptedClientInformation, [pageKey], {});
                    const sectionContent = get(encryptedClientInformation, [pageKey, sectionKey], {});

                    if (sectionItemKey.includes('field')) {
                        const encryptedInformation = await encryptStringWithKey(sectionItemInformation, caseKey);
                        encryptedClientInformation = {
                            ...encryptedClientInformation,
                            [pageKey]: {
                                ...pageContent,
                                [sectionKey]: {
                                    ...sectionContent,
                                    [sectionItemKey]: encryptedInformation,
                                },
                            },
                        };
                    }

                    if (sectionItemKey.includes('popup')) {
                        const encryptedPopupArray: any[] = [];
                        for (let i = 0, n = sectionItemInformation.length; i < n; i++) {
                            const popupItem = sectionItemInformation[i];
                            let encryptPopupItem = {};
                            for (const [popupFieldKey, popupFieldInformation] of Object.entries(popupItem)) {
                                const encryptedPopupInformation = await encryptStringWithKey(
                                    String(popupFieldInformation),
                                    caseKey,
                                );
                                encryptPopupItem = {
                                    ...encryptPopupItem,
                                    [popupFieldKey]: encryptedPopupInformation,
                                };
                            }
                            encryptedPopupArray.push(encryptPopupItem);
                        }

                        encryptedClientInformation = {
                            ...encryptedClientInformation,
                            [pageKey]: {
                                ...pageContent,
                                [sectionKey]: {
                                    ...sectionContent,
                                    [sectionItemKey]: encryptedPopupArray,
                                },
                            },
                        };
                    }
                }
            }
        }
    }
    return encryptedClientInformation;
};

export const getDecodedResults = async (results: unknown, caseKey: string, clientKeys: KeysType) => {
    let encryptedClientInformation = {};
    const unwrappedCaseKey = await unwrapKey(caseKey, clientKeys.privateKey);
    for (const [pageKey, pageInformation] of Object.entries(results as object)) {
        if (pageKey !== 'enc') {
            for (const [sectionKey, sectionInformation] of Object.entries(pageInformation as object)) {
                for (const [sectionItemKey, sectionItemInformation] of Object.entries(sectionInformation as object)) {
                    const pageContent = get(encryptedClientInformation, [pageKey], {});
                    const sectionContent = get(encryptedClientInformation, [pageKey, sectionKey], {});

                    if (sectionItemKey.includes('field')) {
                        const encryptedInformation = await decryptStringWithKey(
                            sectionItemInformation,
                            unwrappedCaseKey,
                        );
                        encryptedClientInformation = {
                            ...encryptedClientInformation,
                            [pageKey]: {
                                ...pageContent,
                                [sectionKey]: {
                                    ...sectionContent,
                                    [sectionItemKey]: encryptedInformation,
                                },
                            },
                        };
                    }

                    if (sectionItemKey.includes('popup')) {
                        const encryptedPopupArray: any[] = [];
                        for (let i = 0, n = sectionItemInformation.length; i < n; i++) {
                            const popupItem = sectionItemInformation[i];
                            let encryptPopupItem = {};
                            for (const [popupFieldKey, popupFieldInformation] of Object.entries(popupItem)) {
                                const encryptedPopupInformation = await decryptStringWithKey(
                                    String(popupFieldInformation),
                                    unwrappedCaseKey,
                                );
                                encryptPopupItem = {
                                    ...encryptPopupItem,
                                    [popupFieldKey]: encryptedPopupInformation,
                                };
                            }
                            encryptedPopupArray.push(encryptPopupItem);
                        }

                        encryptedClientInformation = {
                            ...encryptedClientInformation,
                            [pageKey]: {
                                ...pageContent,
                                [sectionKey]: {
                                    ...sectionContent,
                                    [sectionItemKey]: encryptedPopupArray,
                                },
                            },
                        };
                    }
                }
            }
        }
    }
    return encryptedClientInformation;
};

export const getFileExtension = (fileName: string): string => {
    const fileNameArray = fileName.split('.');
    if (fileNameArray.length > 0) {
        return String(fileNameArray.pop());
    }
    return '';
};

export const checkIsNextPageSubForm = (
    pages: FormPageTypes[],
    subFormRequests: SubFormRequestTypes[],
    index: number,
): boolean => {
    const nextPage = get(pages, [index + 1], null);
    if (nextPage && nextPage.isSubFormPage) {
        return subFormRequests.some(item => item.versionId === nextPage.versionId && !item.isSubmitted);
    }
    return false;
};

export const checkIsLastSubPageForm = (pages: FormPageTypes[], index: number): boolean => {
    const currentPage = pages[index];
    const nextPage = get(pages, [index + 1], null);
    return (
        Boolean(
            currentPage &&
                nextPage &&
                currentPage.isSubFormPage &&
                nextPage.isSubFormPage &&
                currentPage.versionId !== nextPage.versionId,
        ) ||
        (currentPage && currentPage.isSubFormPage && !nextPage)
    );
};

export const allSubFormsSubmitted = (subFormRequests: SubFormRequestTypes[]): boolean => {
    for (let i = 0, n = subFormRequests.length; i < n; i++) {
        const item = subFormRequests[i];
        if (!item.isSubmitted) {
            return false;
        }
    }
    return true;
};

export const isShowContinueButton = (
    status: string,
    subFormRequests: SubFormRequestTypes[],
    isRequesterFormAgentPage: boolean,
    isLastPage: boolean,
    isAgentPage: boolean,
    isImpersonalCase: boolean,
): boolean => {
    if (isRequesterFormAgentPage && isImpersonalCase && status === STATUS_OPENING) {
        return true;
    }
    if (isRequesterFormAgentPage && isLastPage && !isAgentPage) {
        return false;
    } else if (isRequesterFormAgentPage) {
        return true;
    }
    return status === STATUS_OPENING || !allSubFormsSubmitted(subFormRequests);
};

import React, {FC, useContext, useState, useEffect, useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import Markdown from 'react-markdown';
import {throttle} from 'lodash';

import Box from '@mui/material/Box';

import {FormFieldTypes, FormPopupTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';
import {UPDATE_REQUESTER_POPUP} from 'appRedux/actions/requestCase';

import {ClientFormContext} from 'contexts/clientForm/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import EmptyArea from 'components/EmptyArea';

import {FORM_FIELD_TEXT_INPUT, FORM_FIELD_TEXTAREA} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FormFieldSwitcher from 'pages/client/form/partials/FormFieldSwitcher';
import {ContentItemType} from 'pages/client/form/partials/types';
import {isPopupSubmitDisabled, getEmptyPopupRequiredField} from 'pages/client/form/helper';

interface PopupRowFormType {
    toggleModal: () => void;
    initialItem: RequesterCasePopupType;
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    clientInformation: unknown;
    agentInformation: unknown;
    disabled: boolean;
    isAgentPage: boolean;
    onSaveClicked: (isCallAlert?: boolean) => void;
    currentIndex?: number;
}

const PopupRowForm: FC<PopupRowFormType> = ({
    toggleModal,
    initialItem,
    formId,
    pageId,
    sectionId,
    clientInformation,
    agentInformation,
    disabled,
    isAgentPage,
    popup,
    onSaveClicked,
    currentIndex,
}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const {setErrorField} = useContext(ClientFormContext);

    const {id, description, fields} = popup;

    const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [popupContent, setPopupContent] = useState<ContentItemType | null>(
        initialItem?.result ? JSON.parse(initialItem?.result) : null,
    );
    const throttledUpdateRequesterPopup = useRef(
        throttle(
            (newValue, id) => {
                updateRequesterPopup({
                    uuid: initialItem.uuid,
                    popup: id,
                    result: JSON.stringify(newValue),
                    isSubmitted: initialItem.isSubmitted,
                });
            },
            1000,
            {trailing: true, leading: true},
        ),
    );

    const {
        requestCase: {
            isCaseUpdateLoading,
            currentCase: {files, signatures},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateRequesterPopup = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_POPUP.REQUEST, payload: data}),
        [dispatch],
    );

    const onPopupFieldChange = (fieldId: number, value: string) => {
        const fieldKeyword = `field-${fieldId}`;
        setPopupContent(prev => ({...prev, [fieldKeyword]: value}));
        const currentField = fields.find(field => field.id === fieldId);
        if (currentField && ![FORM_FIELD_TEXT_INPUT, FORM_FIELD_TEXTAREA].includes(currentField.type)) {
            throttledUpdateRequesterPopup.current({...popupContent, [fieldKeyword]: value}, id);
        }
    };

    const onSavePopupClicked = (isSaveButtonClicked?: boolean) => {
        if (!currentIndex && !isSaveButtonClicked) return;
        updateRequesterPopup({
            uuid: initialItem.uuid,
            popup: id,
            result: JSON.stringify(popupContent),
            isSubmitted: true,
            callback: () => {
                if (isSaveButtonClicked) {
                    setIsUpdated(true);
                    toggleModal();
                }
            },
        });
    };

    const onDisabledSaveClicked = () => {
        const emptyFieldId = getEmptyPopupRequiredField(popup, popupContent, files, signatures, initialItem.id);
        if (emptyFieldId) {
            setErrorField(emptyFieldId);
        }
    };

    const onModalClose = () => {
        setPopupContent(null);
        toggleModal();
    };

    const isDisabled = isPopupSubmitDisabled(fields, popupContent, files, signatures, initialItem.id);

    console.log('isDisabled', isDisabled);

    useEffect(() => {
        if (isUpdated) {
            onSaveClicked(false);
            onModalClose();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <Box>
            {description && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        '& p': {
                            px: 1,
                            fontSize: 14,
                            fontWeight: 300,
                        },
                    }}
                >
                    <Markdown>{description}</Markdown>
                </Box>
            )}
            <Box>
                {fields &&
                    fields.map((item: FormFieldTypes, index: number) => {
                        return (
                            <FormFieldSwitcher
                                key={`form-popup-field-${index}`}
                                formId={formId}
                                pageId={pageId}
                                sectionId={sectionId}
                                field={item}
                                clientInformation={clientInformation}
                                agentInformation={agentInformation}
                                popupContent={popupContent}
                                popupId={popup.id}
                                onPopupFieldChange={onPopupFieldChange}
                                onSaveClicked={onSavePopupClicked}
                                popupItemId={initialItem.id}
                                setIsDisabledSubmit={setIsDisabledSubmit}
                                disabled={disabled || isCaseUpdateLoading}
                                isAgentPage={isAgentPage}
                            />
                        );
                    })}
            </Box>
            <EmptyArea />
            {!disabled && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                        mb: 1,
                        ml: 'auto',
                    }}
                >
                    <AgentSaveButton
                        data-id={`input#form-popup-${formId}-${pageId}-${sectionId}-${popup.id}-save`}
                        isDisabled={isDisabledSubmit}
                        isBigButton
                        onClick={isDisabled ? onDisabledSaveClicked : () => onSavePopupClicked(true)}
                        isSubmit={false}
                        title={initialItem.isSubmitted ? t('common.buttons.saveButton') : t('common.buttons.add')}
                    />
                </Box>
            )}
        </Box>
    );
};

export default PopupRowForm;

import React, {FC, ReactNode, RefObject, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import ElementDescription from 'pages/client/form/partials/ElementDescription';

import {getPopupKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface FormPopupWrapperType {
    formId: number;
    popup: FormPopupTypes;
    children: ReactNode;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    previewMode?: boolean;
}

const FormPopupWrapper: FC<FormPopupWrapperType> = ({popup, formId, wrapperRef, previewMode, children}) => {
    const [t] = useTranslation();
    const {errorPopup} = useContext(ClientFormContext);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, isRequired, title, description} = popup;

    const isError = id === errorPopup;

    const titleKeyword = getPopupKeyword(formId, id, 'title');

    return (
        <Box ref={wrapperRef}>
            <FormControl fullWidth error={isError} sx={{mt: 1, mb: 1}}>
                <Typography sx={{fontWeight: 700, fontSize: 16}}>{`${getFormTranslatedLabel(
                    translations,
                    titleKeyword,
                    title,
                )} ${isRequired ? '*' : ''}`}</Typography>
                <ElementDescription
                    keyword={getPopupKeyword(formId, id, 'description')}
                    description={description}
                    previewMode={previewMode}
                />
                {children}
                {isError && <FormHelperText>{t('messages.error.errorAtLeastOne')}</FormHelperText>}
            </FormControl>
        </Box>
    );
};

export default FormPopupWrapper;
